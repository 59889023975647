/**
 * Fetch data from the backend API, or from the cache if available.
 *
 * @param {String} endpoint The API endpoint
 * @param {*} opts The options
 *
 * @returns {*} The useFetch method with the API base URL preconfigured
 */
export function useWebsiteFetch(endpoint, opts = { api: 'website', refreshCache: false }) {
	const config = useRuntimeConfig();

	if (opts.key && !opts.refreshCache) {
		let cachedData = useNuxtData(opts.key);

		if (cachedData.data.value) {
			return cachedData;
		}
	}

	const { api, ...optsWithoutApi } = opts;

	return useFetch(endpoint, {
		baseURL: api === 'group' ? config.public.apiBaseURLGroup : config.public.apiBaseURL,
		...optsWithoutApi,
	});
}
